/* IMPORTS ------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

@import 'meyer-reset';
@import 'base';
@import 'gridpak';


/* GENERIC STYLES ------------------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------------------- */

html {
	-webkit-text-size-adjust: 100%; // Keep uniform fonts on device orientation change
	overflow-y: scroll;
}
#noscript_msg, #old_ie_msg {
	display: none;
	z-index: 1001;
	position: relative;
	width: 100%;
	@include type_whitney_ss_reg;
	color: $col_white;
	font-size: em(13);
	line-height: em(19, 13);
}
.msg_inner {
	max-width: 960px;
	margin: 0 auto;
	padding: 10px;
}
#noscript_msg {
	background-color: $col_vdarkgrey;
}
#old_ie_msg {
	background-color: $col_darkgrey;
}
.no-js #noscript_msg {
	display: block;
}
.old-ie #old_ie_msg {
	display: block;
}

body {
	background-color: $col_offwhite;
	// default text styles
	@include type_whitney_ss_reg;
	color: $col_midgrey;
}

div#page {
	position: relative;
	max-width: 960px;
	margin: 0 auto;
	padding: 0;
}

a {
	text-decoration: none;
}
a span.button { // http://stackoverflow.com/questions/796087/make-a-div-into-a-link
	position:absolute; 
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	background-image: url('../../images/empty.gif');
}

// Text block
.textblock {
	a {
	 	color: $col_vdarkgrey;
	 	border-bottom-width: 1px;
		border-bottom-style: dotted;
		border-bottom-color: $col_vdarkgrey;
 	}
 	a.img_link {
 		border: 0 none;
 	}
	h1, h2, h3 {
		@include type_gotham_ss_reg;
		font-size: em(14);
		line-height: 1.25em;
		color: $col_vdarkgrey;
	}
	h2.secondary { // used for secondary section titles on project pages
		color: $col_darkgrey;
	}
	h2.inline_title, h3.inline_title {
		color: $col_midgrey;
	}
	h2.inline_title.primary {
		color: $col_vdarkgrey;
	}
	h1.inline_title, h2.inline_title, h3.inline_title {
		display: inline;
	}
	// BEGIN ADDED FOR PAH LONG TITLE
	.inline_title.inline_title_block {
		display: inline-block;
	}
	.inline_title_block {
		margin-bottom: 0.15em;
	}
	// END ADDED FOR PAH LONG TITLE
	p, ul, span.tb {
		font-size: em(13);
		line-height: em(19, 13);
		margin-bottom: 1.5em; // 19.5px
		padding: 0;
	}
	ul.classic {
		list-style-type: disc;
	}	
	ul.classic li {
		margin-left: 30px;
	}
	p:last-child, ul:last-child, blockquote:last-child {
		margin-bottom: 3em; // 39px
	}
	.last_on_page {
		margin-bottom: 68px !important; // 39px
	}
}
.textblock_table_container {
	margin-bottom: 3em;
	overflow-x: auto;
}
.textblock_table {
	border-collapse: collapse;
    margin: auto;
    border: 1px solid $col_lightgrey;
    border-spacing: initial;
    width: 100%;
    overflow: hidden;
    td, th {
    	border: 1px solid $col_lightgrey;
    	font-size: em(13);
		line-height: em(19, 13);
		padding: 1em;
    }
    th {
    	text-align: left;
    	background-color: $col_ulightgrey;
    }
}

// Horizontal rule
.hr {
	height: 1px;
	background-color: $col_midgrey;
	margin-top: 10px;
	margin-bottom: 10px;
}
.hr.above_img {
	margin-bottom: 15px;
}
hr {
	height: 1px;
	background-color: $col_midgrey;
	margin-top: 8px;
	margin-bottom: 10px;
	border: 0;
}

// Clearfix
.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
} 

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}


/* HEADER INCLUDING NAVIGATION ----------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.logo {
	position: absolute;
	top: -43px;
  	left: 50%;
  	width: 144px;
  	margin-left: -72px;
}

// Menu icon for mobile
#dd_menu_icon { 
	display: none; // Hidden by default
	position: absolute;
	top: 73px;
	right: 10px;
}

nav#top_menu {
	// Layout
	width: 100%;
	height: 65px;
	position: relative;
	// Type
	@include type_gotham_ss_reg;
	font-size: em(13);
	ul {
		display: block;
		width: 100%;
		margin-top: 62px;
		list-style: none;
		text-align: center;
	}
	li:first-child {
		margin-left: 6px; // nudge to better center menu items around logo
	}
	li.mmenu_item {
		position: relative; // necessary to set position here so that the absolutely positioned span inside the anchor tag only fill this containing element when in narrow screen configuration
		vertical-align: middle;
		display: inline-block;
	}
	// set slightly different tracking on menu items either side of logo
	li.reg_mmenu_item_pre_logo, li.last_mmenu_item_pre_logo {
		letter-spacing: em(0.5, 13);
	}
	li.reg_mmenu_item_post_logo, li.last_mmenu_item_post_logo {
		letter-spacing: em(0.4, 13);
	}	
	li.reg_mmenu_item_pre_logo:after, li.reg_mmenu_item_post_logo:after {
		display: inline-block;
		font-size: em(15, 13);
		color: $col_midgrey;
		width: 28px;
		content: '/';
	}
	li.reg_mmenu_item_post_logo:after {
		width: 30px;
	}
	li.last_mmenu_item_pre_logo {
		padding-right: 204px;
	}
	a {
		color: $col_darkgrey;
	}
	a span { // Disable 'a span' button-style links in desktop configuration
		width: 0;
		height: 0;
	}
}

#menu_selector_arrow {
	position: absolute;
	bottom: 0;
	margin-left: -14px;
	//display: none;
}

#site_header_placeholder {
	width: 100%;
	height: 127px;
	display: none;
}

// Mobile configuration
@media only screen and (max-width : 767px) {
	.logo {
		top: 19px;
	  	//left: 20px;
	  	//margin-left: 0px;
	}

	#dd_menu_icon {
		display: block;
		cursor: pointer;
		cursor: hand;
	}

	#menu_selector_arrow {
		display: none;
	}

	#site_header_placeholder {
		display: block;
	}

	nav#top_menu {
		height: auto; // height auto so that block height expands/contracts when dd menu button is pressed
		@include type_gotham_ss_light;
		ul {
			display: none;
			margin-top: 0px;
			margin-left: 0px;
			margin-bottom: 11px;
		}
		li {
			box-sizing: border-box;
			text-align: center;
			display: inline-block;
			width: 33.33%;
			height: 28px;
			line-height: 28px;
			background-color: $col_lightgrey;
			border-right: 1px solid $col_offwhite;
		}
		li.reg_mmenu_item_pre_logo, li.last_mmenu_item_pre_logo {
			border-bottom: 1px solid $col_offwhite;
		}
		li.last_mmenu_item_pre_logo, li.last_mmenu_item_post_logo {
			border-right: none;
		}
		li:first-child {
			margin-left: 0px;
		}
		li.last_mmenu_item_pre_logo {
			padding-right: 0px;
		}
		li.reg_mmenu_item_pre_logo, li.last_mmenu_item_pre_logo, li.reg_mmenu_item_post_logo, li.last_mmenu_item_post_logo {
			letter-spacing: em(0.5, 13); // rebalance tracking when in narrow screen configuration
		}
		li:last-child {
			border-bottom: none;
			//margin-bottom: 12px;
		}
		li.reg_mmenu_item_pre_logo:after, li.reg_mmenu_item_post_logo:after {
			content: none;
		}
		a {
			color: $col_white;
		}
		a span { // Enable 'a span' button-style links in mobile configuration
			width: 100%;
			height: 100%;
		}
	}
}


/* HOME PAGE ONLY STYLES ----------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

// FLEXSLIDER SLIDESHOW
.flexslider img {
	height: auto; // added to keep img aspect ration correct on device orientation change
}
.slideshow_nav_bar {
	// layout
	position: absolute;
	width: 100%;
	bottom: 0;
	padding-left: 11px;
	// bg
	background-color: rgba(45,44,33,0.5);
	// type
	font-size: em(12);
	height: 30px;
	line-height: 30px;
	a {
		color: $col_vlightgrey;
	}
}
// Mobile configuration
@media only screen and (max-width : 500px) {
	.slideshow_proj_client {
		display: none;
	}
}
@media only screen and (max-width : 400px) {
	.slideshow_proj_title {
		display: none;
	}
}
.slides li {
	position: relative;
}
.slides a {
	@include type_gotham_reg;
	font-size: em(13, 12);
}
.slideshow_proj_client {
	color: $col_midlightgrey;
}
.slideshow_badge {
	position: absolute;
	top: 5%;
	left: 4%;
	width: 82px !important;
	height: 82px;
	z-index: 1000;
}
// Mobile configuration
@media only screen and (max-width : 750px) {
	.slideshow_badge {
		width: 72px !important;
		height: 72px;
	}
}
// Mobile configuration
@media only screen and (max-width : 540px) {
	.slideshow_badge {
		width: 62px !important;
		height: 62px;
	}
}

// STRAP LINE
div#strap_line {
	// layout
	margin: 33px 5% 33px 5%;
	// type
	@include type_gotham_light;
	color: $col_darkgrey;
	h1 {
		font-size: em(29);
		line-height: em(38, 29);
	}
	h2 {
		color: $col_midgrey;
		margin-top: 14px;
		font-size: em(22);
		line-height: em(29, 22);
	}
	text-align: center;
}
span.strap_line_segment {
	display: inline-block;
}
// Mobile configuration
@media only screen and (max-width : 995px) {
	div#strap_line {
		h1 {
			font-size: em(27);
			line-height: em(35, 27);
		}
		h2 {
			font-size: em(20);
			line-height: em(26, 20);
		}
	}
}
// Mobile configuration
@media only screen and (max-width : 640px) {
	// Reduce the type size on a screen narrower than 640px
	div#strap_line {
		h1 {
			font-size: em(24);
			line-height: em(33, 24);
		}
		h2 {
			font-size: em(18);
			line-height: em(24, 18);
		}
	}
}
@media only screen and (max-width : 480px) {
	// Reduce the type size on a screen narrower than 640px
	div#strap_line {
		h1 {
			font-size: em(18);
			line-height: em(27, 18);
		}
		h2 {
			font-size: em(14);
			line-height: em(18, 14);
		}
	}
}

// TESTIMONIAL
blockquote {
	margin-bottom: 2em;
	// type
	@include type_whitney_ss_reg_italic;
 	font-size: em(13);
 	line-height: em(19, 13);
 	footer {
		// layout
		display: block;
		// type
		@include type_whitney_ss_reg;
		color: $col_darkgrey;
		font-size: em(12, 13);
		line-height: em(18, 12);
		letter-spacing: -0.0075em;
		text-align: right;
	}
	span.quote_mark_open, span.quote_mark_close {
		// layout
		position: relative;
		display: inline-block;
		width: 18px;
		// type
		@include type_gotham_reg;
		color: $col_cyan;
		font-size: em(28, 13);
		line-height: em(12, 28);
		text-align: center;
	}
	span.quote_mark_open {
		top: 7px;
	}
	span.quote_mark_close {
		top: 9px;
	}
}
blockquote.homepage {
	// layout
	padding-left: 10px;
	padding-right: 10px;
}

#news_section h2.secondary {
	@include type_gotham_ss_reg;
	font-size: em(14);
	line-height: 1.25em;
	color: $col_darkgrey;
}

.newsslider {
	position: relative;
	margin: 0 0 3em 0;
}


.newsarticle {
	background-color: $col_vlightgrey;
	position: relative;
	height: 100%;
	a {
	 	color: $col_vdarkgrey;
	 	border-bottom-width: 1px;
		border-bottom-style: dotted;
		border-bottom-color: $col_vdarkgrey;
 	}
	h3 {
		margin-top: 8px;
		margin-bottom: 6px;
		@include type_gotham_ss_reg;
		font-size: em(12);
		line-height: em(18, 12);
		color: $col_vdarkgrey;
	}
}
.newsimg img {
	width: 100%;
	display: block;
}
.newstext {
	background-color: $col_vlightgrey;
	position: absolute;
	overflow: hidden;
	top: 129px;
	height: 80px;
	padding: 0 10px 0 10px;
	p {
		font-size: em(12);
		line-height: em(18, 12);
	}
}
.newsfooter {
	width: 100%;
	background-color: $col_lightgrey;
	position: absolute;
	bottom: 0;
	height: 30px;
	@include type_whitney_ss_reg;
	line-height: 30px;
	color: $col_white;
	font-size: em(11);
	a {
		border-bottom-style: none;
		color: $col_white;
	}
	.newsreadmorelink {
		float: left;
		margin-left: 10px;
	}
	.newsdate {
		float: right;
		margin-right: 10px;
	}
}
.flex-direction-nav {
	a {
		@include type_gotham_ss_reg;
		font-size: em(12);
		color: $col_midgrey;
	}
}

.newsgradientmask {
	position: absolute;
	bottom: 30px;
	width: 100%;
	height: 12px;
	z-index: 1000;
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U2ZTZlNCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjkwJSIgc3RvcC1jb2xvcj0iI2U2ZTZlNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
	background: -moz-linear-gradient(top,  rgba(230,230,228,0) 0%, rgba(230,230,228,1) 90%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(230,230,228,0)), color-stop(90%,rgba(230,230,228,1))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  rgba(230,230,228,0) 0%,rgba(230,230,228,1) 90%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  rgba(230,230,228,0) 0%,rgba(230,230,228,1) 90%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  rgba(230,230,228,0) 0%,rgba(230,230,228,1) 90%); /* IE10+ */
	background: linear-gradient(to bottom,  rgba(230,230,228,0) 0%,rgba(230,230,228,1) 90%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e6e6e4', endColorstr='#e6e6e4',GradientType=0 ); /* IE6-8 */
}

.flex-direction-nav .flex-disabled {
	display: none;
}

@media only screen and (max-width : 995px) {
	#news_section h2.secondary {
		margin-left: 10px;
	}
	.newsslider {
		margin: 0 10px 3em 10px;
	}
}

// LARGE BUTTONS
.hp_button_grid {
	display: grid;
    grid-template-columns: 10% 1fr 2% 1fr 10%;
    margin-bottom: 2em;
}
.hp_button, .projectpage_button {
	width: 100%;
	position: relative;
	padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	align-items: center;
	background-color: $col_cyan;
	// type
	@include type_gotham_reg;
	font-size: em(17);
	line-height: em(23, 17);
	text-align: center;
	a {
		color: $col_white;
		margin: 0 auto;
		padding-left: 10px;
		padding-right: 10px;
		border: 0;
	}
}
.hp_button_L {
	grid-column-start: 2;
	grid-column-end: 3;
}
.hp_button_R {
	grid-column-start: 4;
	grid-column-end: 5;
}
@media only screen and (max-width : 640px) {
	.hp_button_grid {
		 grid-template-columns: 100%;
		 grid-auto-rows: 1fr;
		 row-gap: 10px;
	}
	.hp_button_L {
		grid-column-start: 0;
		grid-column-end: 1;
		grid-row-start: 0;
		grid-row-end: 1;
	}
	.hp_button_R {
		grid-column-start: 0;
		grid-column-end: 1;
		grid-row-start: 1;
		grid-row-end: 2;
	}
}


/* FOOTER STYLES ------------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

footer.page_footer {
	margin-top: 20px;
	a {
	 	color: $col_vdarkgrey;
	 	border-bottom-width: 1px;
		border-bottom-style: dotted;
		border-bottom-color: $col_vdarkgrey;
		text-decoration: none;
 	}
 	a.smgrey {
 		color: $col_lightgrey;
 		border-bottom-color: $col_lightgrey;
 	}
	font-size: em(12);
	line-height: em(18, 12);
	letter-spacing: -0.0075em;
	color: $col_midgrey;
	ul#footer_menu {
		margin-bottom: 10px;
		li {
			display: inline-block;
		}
		li:after {
			content: ' / ';
		}
		li:last-child:after {
			content: '';
		}
	}
}
div.social_links {
	margin-bottom: 14px;
	ul {
		a {
			border: none;
		}
		li {
			padding-right: 6px;
			display: inline-block;
		}
		li:last-child {
			padding-right: 0;
		}
	}
}
.footer_links {
	display: inline-block;
}
.footer_body {
	padding: 0 10px 0 10px;
	text-align: center;
}
.footer_email {
	float: left;
	margin-right: 20px;
	//margin-bottom: 10px;
}
.footer_phone_number {
	float: right;
	margin-left: 20px;
	//margin-bottom: 10px;
}
.copyright_notice {
	color: $col_lightgrey;
	font-size: em(11, 12);
	line-height: em(17, 12);
	margin: 0 0 10px 0;
}
// Mobile configuration
@media only screen and (max-width : 640px) {
	.footer_body {
		padding: 0;
	}
	.footer_links {
		display: block;
		margin-bottom: 8px;
	}
	.footer_phone_number, .footer_email {
		margin: 0;
		float: none;
	}
	.footer_email:after {
		content: ' / ';
	}
	.copyright_notice {
		margin-top: 10px;
	}
}


/* TITLE BAR STYLES ---------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.title_bar {
	min-height: 28px;
	text-align: center;
	padding: 0 10px 0 10px;
	h1 {
		@include type_gotham_ss_reg;
		font-size: em(15);
		color: $col_vdarkgrey;
		line-height: 28px;
	}
}
.title_bar, .title_bar_vid_pg {
	background-color: $col_vlightgrey;
}
.title_bar {
	margin-bottom: 88px;
}
.title_bar_vid_pg {
	margin-bottom: 11px;
}
.work_menu_bar {
	padding: 0;
	background-color: $col_cyan;
	margin-bottom: 11px;
	a {
		color: $col_white;
	}
	a.current {
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: $col_white;
	}
	li {
		@include type_gotham_ss_light;
		font-size: em(13);
		color: $col_white;
		display: inline;
		line-height: 28px;
	}
	li:after {
		content: '\00a0\00a0/\00a0\00a0'; // double space - slash - double space
	}
	li:last-child:after {
		content: ''; // ensure no traling slash after last item
		width: 0px;
	}
}
// Mobile configuration
@media only screen and (max-width : 720px) {
	.work_menu_bar {
		padding: 1px 0 1px 0;
		li {
			line-height: 26px;
		}
		li#item03:after {
			content: "\A"; // line break instead of trailing slash after 3rd item
			white-space:pre;
		}
	}
}
@media only screen and (max-width : 360px) {
	.work_menu_bar {
		padding: 2px 0 2px 0;
		li {
			line-height: 24px;
			font-size: em(12); // to keep menu on 2 lines on iphone 4S & below
		}
		li:after {
			content: '\00a0/\00a0'; // single space - slash - single space
		}
		/*li#item03:after {
			content: "\A"; // line break
			white-space:pre;
		}*/
	}
}
.title_bar_vid_pg {
	padding: 0 10px 0 10px;
	a {
		color: $col_midgrey;
		@include type_gotham_ss_reg;
		font-size: em(12);
		line-height: 28px;
	}
	a.disabled {
		color: $col_lightgrey;
	}
	.angle_bracket {
		margin-top: 1px;
		font-size: em(14, 12);
	}
}
#title_bar_back_link {
	float: left;
}
#title_bar_next_and_prev_links {
	float: right;
}
#title_bar_next_link, #title_bar_prev_link {
	display: inline-block;
	margin-left: 20px;
}
// Mobile configuration
@media only screen and (max-width : 480px) {
	#title_bar_next_and_prev_links {
		display: block;
		width: 100%;
	}
	#title_bar_prev_link {
		margin-left: 0;
		float: left;
	}
	#title_bar_next_link {
		float: right;
	}
}


/* ABOUT PAGE STYLES --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.about_pg_col_01, .about_pg_col_02 {
	width:37.5%;
    border-left-width:0;
    padding:0;
    border-right-width:16px;
}
.about_pg_spacer_col {
	width:12.5%;
    padding:0;
    margin-left:0;
}
.about_pg_gutter_col {
	width:32px;
    border-left-width:0;
    padding:0;
    margin-left:0;
}
// Mobile configuration
@media only screen and (max-width : 640px) {
	.about_pg_col_01, .about_pg_col_02 {
		@include span(6, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.about_pg_col_02 {
		margin-left: 12.5%;
	}
	.about_pg_spacer_col {
		@include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
}


/* SHOWREEL PAGE STYLES ------------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------------------- */

.showreel_pg_col_01 {
	@include span(8, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.showreel_pg_col_01 .video-js {
	margin-bottom: 39px;
}


/* CLIENT PAGE STYLES -------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.client_pg_col_01 {
	@include span(3, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.client_pg_col_02 {
	@include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.client_pg_spacer_col {
	@include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.clientlogo {
	display: block;
	float: left;
	width: 50%;
}
// Mobile configuration
@media only screen and (max-width : 780px) {
	.clientlogo {
		width: 50%;
	}
}
@media only screen and (max-width : 640px) {
	.client_pg_col_01 {
		@include span(6, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.client_pg_col_02 {
		@include span(6, $gutter_pc, $gutter_px, $padding, $max_columns);
		margin-left: 12.5%;
	}
	.clientlogo {
		width: 20%;
	}
}
@media only screen and (max-width : 420px) {
	.clientlogo {
		width: 25%;
	}
}


/*  PEOPLE PAGE STYLES ------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.people_pg_col, .error_pg_col {
	overflow:auto;
	@include span(4, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.people_pg_spacer_col, .error_pg_spacer_col {
	@include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
}
// Mobile configuration
@media only screen and (max-width : 767px) {
	.people_pg_spacer_col, .error_pg_spacer_col  {
		@include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.people_pg_col, .error_pg_col {
		@include span(6, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
}
.people_profile_text {
	display: table-cell;
	padding-left: 16px;
}
.people_profile_photo {
	display: table-cell;
	vertical-align: top;
}
@media only screen and (max-width : 420px) {
	.people_profile_text {
		display: block;
		padding-left: 0;
	}
	.people_profile_photo {
		display: block;
		float: left;
		padding-right: 16px;
	}
}


/*  CONTACT PAGE STYLES ------------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------------------- */


.contact_pg_spacer_col_01 {
	@include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.contact_pg_spacer_col_02 {
	@include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.contact_pg_col_01 {
	@include span(3, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.contact_pg_col_02 {
	@include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
}
// Mobile configuration
@media only screen and (max-width : 640px) {
	.contact_pg_spacer_col_01 {
		@include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.contact_pg_col_01 {
		@include span(5, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.contact_pg_col_02 {
		@include span(5, $gutter_pc, $gutter_px, $padding, $max_columns);
		margin-left: 25%;
	}
}


.contact_pg_spacer_col_01_alt {
	@include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.contact_pg_spacer_col_02_alt {
	@include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.contact_pg_col_01_alt {
	@include span(4, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.contact_pg_col_02_alt {
	@include span(4, $gutter_pc, $gutter_px, $padding, $max_columns);
}
// Mobile configuration
@media only screen and (max-width : 640px) {
	.contact_pg_spacer_col_01_alt {
		@include span(2, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.contact_pg_col_01_alt {
		@include span(5, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.contact_pg_col_02_alt {
		@include span(5, $gutter_pc, $gutter_px, $padding, $max_columns);
		margin-left: 25%;
	}
}
.contact_section {
	position: relative;
}
.contact_icon_jobs, .contact_icon_mail, .contact_icon_phone, .contact_icon_email {
	position: absolute;
}
.contact_icon_jobs {
	top: -10px;
	left: -58.5px;
}
.contact_icon_mail {
	top: -20px;
	left: -65px;
}
.contact_icon_phone {
	top: -4px;
	left: -56px;
}
.contact_icon_email {
	top: -6px;
	left: -53px;
}

div.contact_cta {
	
	//border: 3px solid #e6e6e4;
	//border-radius: 16px;
	

	background-color: #e6e6e4;
	padding: 16px;
	margin-bottom: 3em;
	p {
		@include type_gotham_ss_light;
		font-size: em(13);
		color: $col_darkgrey;
		margin-bottom: 1em;
	}
	p:last-child {
		margin-bottom: 0;
	}
	p.center {
		text-align: center;
	}
	span#obf2 a, a.contact_cta_link {
		text-decoration: none;
		border-bottom-style: none;
		color: $col_darkgrey;
	}
}


/*  WORK PAGE STYLES --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.work_menu_grid {
	padding: 0;
}
// Mobile configuration
@media only screen and (max-width : 995px) {
	.work_menu_grid {
		padding: 0 10px 0 10px;
	}
}
.work_menu_item {
	position: relative;
	margin-bottom: 11px;
	background-color: $col_vlightgrey;
	img {
		margin-bottom: 10px;
		width: 100%;
		display: block;
		height: auto;
	}
	h1, h2 {
		@include type_gotham_ss_reg;
		font-size: em(12);
		line-height: em(16, 12);
		padding: 0 10px 0 10px;
		margin-bottom: 8px;
	}
	h1 {
		color: $col_vdarkgrey;
	}
	h2 {
		color: $col_midgrey;
	}
}
.work_menu_item:last-child {
	margin-bottom: 39px;
}
.work_menu_item_titleblock {
	word-wrap:break-word;
	height: 75px;
}
.view_project_bar {
	font-size: em(12);
	padding: 0 10px 0 10px;
	line-height: 30px;
	height: 30px;
	background-color: $col_lightgrey;
	a {
		color: $col_white;
	}
}

.cat_page_text.textblock {
	p:last-child {
		margin-bottom: 1.5em;
	}
}

.case_study_label {
	position: absolute;
	top: 6px;
	right: 6px;
	background-color: $col_cyan;
	padding: 6px;
	border-radius: 6px;

	@include type_gotham_ss_reg;
	font-size: em(12);
	line-height: em(14, 12);
	color: $col_white;
	text-align: center;
}
/*
.col.textblock {
	padding-left: 10px;
}
@media only screen and (max-width : 995px) {
	.col.textblock {
		padding-left: 0;
	}
}
*/


/*  PROJECT PAGE STYLES ------------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------------------- */
.projectstill {
	width: 100%;
	height: auto;
	margin-bottom: 28px;
}
.project_pg_spacer_col {
	width: 0;
	//@include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
}
video.main_video {
	width: 100%;
}
.vid {
	@include span_first;
    width:100%;
}
.video-js, .video-vimeo, .projectimg, .projectflash {
	margin-bottom: 28px;
}
.projectimg img {
	max-width: 100%;
}
.projectflash {
	position: relative;
	padding-bottom: 64.94%;
	height: 0;
}
.projectflash object {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
#similar_work_menu_grid {
	padding: 0;
}
// Mobile configuration
@media only screen and (max-width : 995px) {
	#similar_work_menu_grid {
		padding: 0 10px 0 10px;
	}
}
.similar_work_menu_item {
	img {
		width: 100%;
		height: auto;
		display: block;
	}
	margin-bottom: 11px;
}
.projectpage_col_01 {
	float: left;
	width: 472+16px;
	margin: 0;
	padding: 0;
}
.projectpage_col_02 {
	float: right;
	width: 350+16px;
	margin: 0;
	padding: 0;
}
.projectpage_col_01, .projectpage_col_02 {
	margin-bottom: 1em;
}
/*
.thumbnail, .thumbnail_ext {
	position: relative;
    width:25%;
    border-left-width:16px;
    padding:0;
    margin-bottom: 16px;
}
.thumbnail img, .thumbnail_ext img {
	width: 100%;
	height: auto;
	display: block;
}
.thumbnail.current {
	cursor: default;
}
.thumbnail.current:after {
	@include type_gotham_ss_reg;
	background-color: $col_vdarkgrey;
	text-align: center;
	font-size: em(10);
	position: absolute;
	height: 20px;
	line-height: 20px;
	left: 0;
	right: 0;
	bottom: 0;
	color: $col_white;
	content: 'PLAYING';
}*/
#projectpage_more_videos {
	min-height: 18px;
}
.more_videos_grid {
	margin-bottom: 23px;
}

// NEW - CASE STUDY UPDATE FEB 2022
.projectpage_thumbnail_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 23px;
}
.projectpage_thumbnail_item, .projectpage_thumbnail_item_ext {
	position: relative;
}
.projectpage_thumbnail_item img, .projectpage_thumbnail_item_ext img {
	width: 100%;
	height: auto;
	display: block;
}
.projectpage_thumbnail_item.current:after, .projectpage_thumbnail_item_ext.current:after {
	@include type_gotham_ss_reg;
	background-color: $col_vdarkgrey;
	text-align: center;
	font-size: em(10);
	position: absolute;
	height: 20px;
	line-height: 20px;
	left: 0;
	right: 0;
	bottom: 0;
	color: $col_white;
	content: 'PLAYING';
}
.projectpage_thumbnail_item.current, .projectpage_thumbnail_item_ext.current {
	cursor: default;
}
.projectpage_description_section p:last-child {
	margin-bottom: 0;
}
.projectpage_description_section {
	margin-bottom: 2em;
}
#projectpage_testimonial blockquote:last-child {
	margin-bottom: 2em;
}
.projectpage_description_still {
	width: 100%;
	height: auto;
}
.projectpage_description_strapline {
	@include type_whitney_ss_reg_italic;
}

// Mobile configuration
@media only screen and (max-width : 995px) {
	.projectpage_thumbnail_grid {
	    padding-right: 10px;
	}
	.projectpage_button_wrapper {
		margin-right: 10px;
	}
	/*
	.thumbnail {
		border-left-width: 10px;
		margin-bottom: 10px;
	}
	*/
	#projectpage_more_videos {
		min-height: 0px;
	}
	#projectpage_similar_work h2 {
		padding-left: 10px;
	}
	.projectpage_description_header {
		padding-left: 10px;
	}
	.description_section_r .projectpage_description_header {
		padding-left: 0;
	}
	.projectpage_col_01 p, .projectpage_col_01 blockquote, .projectpage_col_01 ul {
		padding-left: 10px;
	}
	.projectpage_col_02 p, .projectpage_col_02 blockquote, .projectpage_col_02 ul {
		padding-right: 10px;
	}
	.projectpage_col_01 h1.inline_title {
		padding-left: 0;
	}
	.more_videos_grid {
		padding-right: 10px;
	}
}
@media only screen and (max-width : 880px) {
	/*.projectpage_col_01 .thumbnail {
    	width:33.3%;
	}*/
	.projectpage_button_wrapper {
		margin-right: 10px;
	}
	.projectpage_col_01 {
		width: 366px;
	}
}
@media only screen and (max-width : 760px) {
	.projectpage_button_wrapper {
		margin-left: 10px;
		margin-right: 10px;
	}
	.projectpage_thumbnail_grid {
	    padding-left: 10px;
	    grid-template-columns: repeat(8, 1fr);
	}

	#projectpage_more_videos h2, .description_section_r h2 {
		padding-left: 10px;
	}
	.more_videos_grid {
		padding-left: 10px;
	}
	#projectpage_more_videos {
		min-height: 0px;
	}
	/*.projectpage_col_01 .thumbnail {
    	width:12.5%;
	}*/
	.projectpage_col_01 {
		float: none;
		@include span(8, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	.projectpage_col_02 {
		float: none;
		@include span(0, $gutter_pc, $gutter_px, $padding, $max_columns);
	}
	#projectpage_more_videos h1 {
		padding-left: 10px;
	}
	.projectpage_col_01 p, .projectpage_col_01 blockquote, .projectpage_col_01 ul {
		padding-left: 10px;
		padding-right: 10px;
	}
}
@media only screen and (max-width : 640px) {
	.projectpage_thumbnail_grid {
	    grid-template-columns: repeat(4, 1fr);
	}
	/*.projectpage_col_01 .thumbnail {
    	width:25%;
	}*/
}

/* POLICY PG STYLES APR 2019 ------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

.policy_pg_spacer_col  {
	@include span(1, $gutter_pc, $gutter_px, $padding, $max_columns);
}
.policy_pg_col {
	@include span(6, $gutter_pc, $gutter_px, $padding, $max_columns);
}

ul.policy {
	list-style-type: disc;
	list-style-position: outside;
	margin-left: 20px;
	li {
		position: relative;
		left: -5px;
	}
}
span.italic {
	@include type_whitney_ss_reg_italic;
}


/* ADDITIONAL STYLES --------------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------- */

/* KEEP AT END OF STYLESHEET - prevents faux bolding of fonts in old version of internet explorer */
 .old-ie * {
   font-weight: normal !important;
   font-style:  normal !important;
}

