/**
 * Gridpak Beta SCSS 
 *
 * Generator - http://gridpak.com/
 * Created by @erskinedesign
 */

// Added to help eliminate divide using '/' operator in span function
@use "sass:math";
 
@mixin col {
    border:0px solid rgba(0,0,0,0);
    float:left;
    -webkit-box-sizing:border-box;
    -moz-box-sizing:border-box;
    box-sizing:border-box;
    -moz-background-clip:padding-box !important;
    -webkit-background-clip:padding-box !important;
    background-clip:padding-box !important;
}

.col {
   @include col;
}

@mixin span($num, $gutter_pc, $gutter_px, $padding, $max_columns) {
    $one_col: math.div(100% - ($gutter_pc * ($max_columns - 1)), $max_columns);
    width:($one_col * $num) + ($gutter_pc * ($num - 1));
    border-left-width:$gutter_px;
    padding:$padding;
    margin-left:$gutter_pc;
}

@mixin span_first {
    margin-left:0;
}
 
.row {
    margin-left:-16px;
}

.col {
    border-left-width:16px;
    padding:0 0px;
}

/* --- Gridpak variables ---*/ 
$max_columns: 8;
$padding: 0 0px;
$gutter_px: 16px;
$gutter_pc: 0;

/* CSS for grids of work items and similar work items */
@media screen and (min-width: 0px) and (max-width: 299px) {
    .span_1 {
        @include span_first;
        width:100%;
    }
} 

@media screen and (min-width: 300px) and (max-width: 619px) {
    .row_grid {
        margin-left:-10px;
    }
    .col_grid {
        border-left-width:10px;
        padding:0 0px;
    }
    /* --- Gridpak variables ---*/ 
    $grid_gutter_px: 10px;
    $max_grid_columns: 2;
    .span_1 {
        @include span(1, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_2 {
        @include span_first;
        width:100%;
    }
}  

@media screen and (min-width: 620px) and (max-width: 859px) {
    .row_grid {
        margin-left:-10px;
    }
    .col_grid {
        border-left-width:10px;
        padding:0 0px;
    }
    /* --- Gridpak variables ---*/ 
    $grid_gutter_px: 10px;
    $max_grid_columns: 3;
    .span_1 {
        @include span(1, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_2 {
        @include span(2, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_3 {
        @include span_first;
        width:100%;
    }
}

@media screen and (min-width: 860px) and (max-width: 959px) {
    .row_grid {
        margin-left:-10px;
    }
    .col_grid {
        border-left-width:10px;
        padding:0 0px;
    }
    /* --- Gridpak variables ---*/
    $grid_gutter_px: 10px; 
    $max_grid_columns: 4;
    .span_1 {
        @include span(1, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_2 {
        @include span(2, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_3 {
        @include span(3, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_4 {
        @include span_first;
        width:100%;
    }
}

@media screen and (min-width: 960px) {
    .row_grid {
        margin-left:-16px;
    }
    .col_grid {
        border-left-width:16px;
        padding:0 0px;
    }
    /* --- Gridpak variables ---*/
    $grid_gutter_px: 16px; 
    $max_grid_columns: 4;
    .span_1 {
        @include span(1, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_2 {
        @include span(2, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_3 {
        @include span(3, $gutter_pc, $grid_gutter_px, $padding, $max_grid_columns);
    }
    .span_4 {
        @include span_first;
        width:100%;
    }
}
 
