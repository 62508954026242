@use "sass:math";

// Colours
$col_white: #ffffff;
$col_offwhite: #fcfcfa;
$col_ulightgrey: #f8f8f8;
$col_vlightgrey: #e6e6e4;
$col_lightgrey: #c8c8c6;
$col_midlightgrey: #d1d1cf;
$col_midgrey: #7f7f7a;
$col_darkgrey: #5b5954;
$col_vdarkgrey: #2d2c21;
$col_black: #000000;
$col_cyan: #00adee;

// Type
@mixin type_gotham_ss_reg {
	font-family: 'Gotham Rounded SSm 4r', 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
}
@mixin type_gotham_ss_light {
	font-family: 'Gotham Rounded SSm 3r', 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 300;
	font-style: normal;
}
@mixin type_gotham_light {
	font-family: 'Gotham Rounded 3r', 'Gotham Rounded A', 'Gotham Rounded B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 300;
	font-style: normal;
}
@mixin type_gotham_reg {
	font-family: 'Gotham Rounded 4r', 'Gotham Rounded A', 'Gotham Rounded B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
}
@mixin type_whitney_ss_reg {
	font-family: 'Whitney SSm 4r', 'Whitney SSm A', 'Whitney SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
}
@mixin type_whitney_ss_reg_italic {
	font-family: 'Whitney SSm 4i', 'Whitney SSm A', 'Whitney SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-style: italic;
}
// Em function adopted from Bourbon
@function em($target-px, $context-px: 16) {
  @return #{math.div($target-px, $context-px)}em;
}